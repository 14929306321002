import React, {FC} from "react";
import { useNavigate } from "react-router-dom";

const Footer:FC = () => {

    const navigate = useNavigate()

    return(
        <div className="footerContainer">
            <a href="" onClick={()=>window.open('https://www.nearway.fr/static/media/apple-privacy-policy-fr-ww.d7203a4fc96475eca53c.pdf')}>Confidentialité</a>
            <a href="mailto:contact@tippon.fr">Contact</a>
            <a href="#" onClick={()=>{navigate("/help")}}>Aide</a>
        </div>
    )

}

export default Footer