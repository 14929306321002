import './app.scss';
import Footer from './footer';
import Header from './header';
import AppStoreBadge from "./images/app_store_badge.svg";
import GooglePlayBadge from "./images/google_play_badge.svg";
import Mockup from "./images/mockup.svg";

function App() {
  return (
    <>
    <Header />
    <div className="appContainer">
      <hr/>
      <div className="mainSection">
        <div className="mainSection__titleAndStoreBadges">
          <h1 className="mainSection__titleAndStoreBadges__title">Un Clic, <br/>
            <span className="mainSection__titleAndStoreBadges__title__withBackground">une pépite.</span>
          </h1>
          <div className="mainSection__titleAndStoreBadges__badges">
            <img className="mainSection__titleAndStoreBadges__badges__badge" src={AppStoreBadge}/>
            <img className="mainSection__titleAndStoreBadges__badges__badge" src={GooglePlayBadge}/>
          </div>
        </div>
        <img className="mainSection__mockup" src={Mockup}/>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default App;
