import React, {FC} from "react";
import logo from "./images/logo.svg";


const Header: FC = () => {

    return(
        <div className="headerContainer">
            <img className="headerContainer__logo" src={logo} alt="nearway" />
        </div>
    )
}

export default Header;